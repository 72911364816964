import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type Watchlist {
    _id: ID!
    userId: ID!
    items: [Vehicle]
  }

  extend type Query {
    getUserWatchlist: [Vehicle]
    getWatchlists(query: String): [Watchlist]
    #BOILERPLATER_INJECT_QUERY
  }
  extend type Mutation {
    addItemToWatchlist(itemId: ID): Watchlist
    removeItemFromWatchlist(itemId: ID): Watchlist

    deleteWatchlist: Watchlist
    # BOILERPLATER_INJECT_MUTATION
  }
`;

export default typeDefs;
