import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type Company {
    name: String
    legalName: String
    companyId: String
    about: String
    website: String
    logo: String
    banner: String
    location: Location
  }

  input CompanyInput {
    name: String
    legalName: String
    companyId: String
    about: String
    website: String
    logo: String
    banner: String
    location: LocationInput
  }

  type User {
    _id: ID
    username: String
    email: String
    displayName: String
    firstName: String
    lastName: String
    secondLastName: String
    primaryPhone: String
    secondaryPhone: String
    image: String
    company: Company
    joinDate: String
    lastUpdated: String
    idNumber: String
    emailVerified: Boolean
    idVerified: Boolean
    idUrl: String
    faceWithIdUrl: String
    accountStatus: String
    subscriptionType: String
  }

  input UserInput {
    username: String
    email: String
    displayName: String
    firstName: String
    lastName: String
    secondLastName: String
    primaryPhone: String
    secondaryPhone: String
    image: String
    company: CompanyInput
    joinDate: String
    lastUpdated: String
    idNumber: String
    emailVerified: Boolean
    idVerified: Boolean
    idUrl: String
    faceWithIdUrl: String
    accountStatus: String
    subscriptionType: String
  }

  extend type Query {
    getAllUsers: [User]
    getUser(_id: ID, email: String, username: String): User
  }

  extend type Mutation {
    updateUser(user: UserInput!): User

    verifyUserId(_id: ID!, idUrl: String, faceWithIdUrl: String): User
    deactivateUserAccount(_id: ID!): User
    reactivateUserAccount(_id: ID!): User
  }
`;

export default typeDefs;
