import brands from "../public/brands.json";
import MODEL_SLUGS from "../public/model_slugs.json";

import FBLink from "../components/FBLink";
import IGLink from "../components/IGLink";
import { capitalizeAllWords, generateSlug } from "./formatters";

export const MOVI_NUMBER_FORMATED = "+506 8699-1661";
export const WHATSAPP_HREF = "https://wa.me/50686991661";
export const FACEBOOK_PAGE_URL = "https://www.facebook.com/movi.autoscr";
export const INSTAGRAM_PAGE_URL = "https://www.instagram.com/movi.autos";

export const MIN_ALLOWED_UPLOADS = 4;
export const MAX_ALLOWED_UPLOADS = 40;
export const MAX_ALLOWED_SIZE = 15;

export const USER_ID_IMAGE_UPLOAD_PRESET = "users_id";
export const USER_AVATAR_IMAGE_UPLOAD_PRESET = "users_avatar";
export const VEHICLE_IMAGE_UPLOAD_PRESET = "car_pictures";

export const HTTP_ERROR_CODES = [400, 401, 403, 404, 500, 502, 503, 504];

export const SUBSCRIPTION_TYPES = {
  free: "free",
  pro: "pro",
};

export const ID_VERIFICATION_STATUSES = {
  unverified: "unverified",
  pending: "pending",
  verified: "verified",
  rejected: "rejected", // This is manually given
};

export const ACCOUNT_STATUSES = {
  ACTIVE: "active",
  DEACTIVATED: "deactivated",
};

export const LISTING_STATUSES = {
  active: "active",
  expiredByUser: "expiredByUser", // This is actually not being used as the expiration is validated against bumpedDate, there should be a mutation expireVehicle that sets bumped date to the moment it was excecuted
  deletedByUser: "deletedByUser",
  userDeactivated: "userDeactivated", // listings get this status when the user has deactivated the account,
  deletedByAdmin: "deletedByAdmin",
};

export const DELETION_CAUSES = {
  soldInside: {
    title: "Lo vendí en Movi",
  },
  soldOutside: {
    title: "Lo vendí fuera de Movi",
  },
  notSelling: {
    title: "Decidí no venderlo",
  },
  other: {
    title: "Otra razón",
  },
};

export const SORT_KEYS = {
  LATEST: "latest",
  HIGHEST: "highest",
  LOWEST: "lowest",
};

export const FUEL_KEYS = {
  GASOLINE: "gasoline",
  DIESEL: "diesel",
  LPG: "lpg",
  ELECTRIC: "electric",
  HYBRID: "hybrid",
  HYDROGEN: "hydrogen",
};

export const FUELS = {
  [FUEL_KEYS.GASOLINE]: {
    id: FUEL_KEYS.GASOLINE,
    label: "Gasolina",
    isDefault: true,
  },
  [FUEL_KEYS.DIESEL]: {
    id: FUEL_KEYS.DIESEL,
    label: "Diesel",
  },
  [FUEL_KEYS.LPG]: {
    id: FUEL_KEYS.LPG,
    label: "Gas LP",
  },
  [FUEL_KEYS.ELECTRIC]: {
    id: FUEL_KEYS.ELECTRIC,
    label: "Eléctrico",
  },
  [FUEL_KEYS.HYBRID]: {
    id: FUEL_KEYS.HYBRID,
    label: "Híbrido",
  },
  [FUEL_KEYS.HYDROGEN]: {
    id: FUEL_KEYS.HYDROGEN,
    label: "Hidrógeno",
  },
};

export const CURRENCY_KEYS = {
  CRC: "CRC",
  USD: "USD",
};

export const CURRENCIES = {
  [CURRENCY_KEYS.CRC]: {
    label: "Colones",
    symbol: "₡",
  },
  [CURRENCY_KEYS.USD]: {
    label: "Dólares",
    symbol: "$",
  },
};

export const TRANSMISSION_KEYS = {
  MANUAL: "manual",
  AUTOMATIC: "automatic",
  SEMI: "semi",
};
export const TRANSMISSIONS = {
  [TRANSMISSION_KEYS.MANUAL]: {
    id: TRANSMISSION_KEYS.MANUAL,
    label: "Manual",
    isDefault: true,
  },
  [TRANSMISSION_KEYS.AUTOMATIC]: {
    id: TRANSMISSION_KEYS.AUTOMATIC,
    label: "Automático",
  },
  [TRANSMISSION_KEYS.SEMI]: {
    id: TRANSMISSION_KEYS.SEMI,
    label: "Semi-automático",
  },
};

export const MILEAGE_UNIT_KEYS = {
  KILOMETERS: "km",
  MILES: "mi",
};
export const MILEAGE_UNITS = {
  [MILEAGE_UNIT_KEYS.KILOMETERS]: {
    label: "kilometros",
    labelShort: "km",
  },
  [MILEAGE_UNIT_KEYS.MILES]: {
    label: "millas",
    labelShort: "mi",
  },
};

export const DRIVETRAIN_KEYS = {
  FWD: "FWD",
  RWD: "RWD",
  AWD: "AWD",
  ["4WD"]: "4WD",
};

export const DRIVETRAINS = {
  [DRIVETRAIN_KEYS.FWD]: {
    id: DRIVETRAIN_KEYS.FWD,
    label: "Delantera",
    isDefault: true,
  },
  [DRIVETRAIN_KEYS.RWD]: {
    id: DRIVETRAIN_KEYS.RWD,
    label: "Trasera",
  },
  [DRIVETRAIN_KEYS.AWD]: {
    id: DRIVETRAIN_KEYS.AWD,
    label: "All-Wheel Drive",
  },
  [DRIVETRAIN_KEYS["4WD"]]: {
    id: DRIVETRAIN_KEYS["4WD"],
    label: "4x4",
  },
};

//export const CONDITIONS = ["n", "e", "vg", "g", "f", "p", "nf"]; //"New", "Excellent", "Very Good", "Good", "Fair", "Poor", "Non-Functioning"
export const CONDITION_KEYS = {
  NEW: "new",
  EXCELLENT: "excellent",
  VERY_GOOD: "very good",
  GOOD: "good",
  FAIR: "fair",
  POOR: "poor",
  // NON_FUNCTIONING: "non-functioning",
};
export const CONDITIONS = {
  [CONDITION_KEYS.NEW]: {
    id: CONDITION_KEYS.NEW,
    label: "Nuevo",
    isDefault: true,
  },
  [CONDITION_KEYS.EXCELLENT]: {
    id: CONDITION_KEYS.EXCELLENT,
    label: "Excelente",
  },
  [CONDITION_KEYS.VERY_GOOD]: {
    id: CONDITION_KEYS.VERY_GOOD,
    label: "Muy buena",
  },
  [CONDITION_KEYS.GOOD]: {
    id: CONDITION_KEYS.GOOD,
    label: "Buena",
  },
  [CONDITION_KEYS.FAIR]: {
    id: CONDITION_KEYS.FAIR,
    label: "Regular",
  },
  [CONDITION_KEYS.POOR]: {
    id: CONDITION_KEYS.POOR,
    label: "Mala",
  },
  // [CONDITION_KEYS.NON_FUNCTIONING]: {
  //   id: CONDITION_KEYS.NON_FUNCTIONING,
  //   label: "Sin funcionar",
  // },
};

export const CATEGORY_KEYS = {
  SEDAN: "sedan",
  SUV: "suv",
  PICKUP: "pickup",
  HATCHBACK: "hatchback",
  VAN: "van",
  COUPE: "coupe",
  CONVERTIBLE: "convertible",
  SPORT: "sport",
  MICRO: "micro",
  LCV: "lcv",
  MCV: "mcv", // PENDING
  HCV: "hcv",
};
export const CATEGORIES = {
  [CATEGORY_KEYS.SEDAN]: {
    id: CATEGORY_KEYS.SEDAN,
    label: "Sedán",
    icon: "/icons/sedan.svg",
    slug: CATEGORY_KEYS.SEDAN,
  },
  [CATEGORY_KEYS.SUV]: {
    id: CATEGORY_KEYS.SUV,
    label: "SUV/Crossover",
    icon: "/icons/suv_crossover.svg",
    slug: CATEGORY_KEYS.SUV,
  },
  [CATEGORY_KEYS.PICKUP]: {
    id: CATEGORY_KEYS.PICKUP,
    label: "Pickup",
    icon: "/icons/pickup.svg",
    slug: CATEGORY_KEYS.PICKUP,
  },
  [CATEGORY_KEYS.HATCHBACK]: {
    id: CATEGORY_KEYS.HATCHBACK,
    label: "Hatchback",
    icon: "/icons/hatchback.svg",
    slug: CATEGORY_KEYS.HATCHBACK,
  },
  [CATEGORY_KEYS.VAN]: {
    id: CATEGORY_KEYS.VAN,
    label: "Buseta",
    icon: "/icons/minivan.svg",
    slug: CATEGORY_KEYS.VAN,
  },
  [CATEGORY_KEYS.COUPE]: {
    id: CATEGORY_KEYS.COUPE,
    label: "Coupé",
    icon: "/icons/coupe.svg",
    slug: CATEGORY_KEYS.COUPE,
  },
  [CATEGORY_KEYS.CONVERTIBLE]: {
    id: CATEGORY_KEYS.CONVERTIBLE,
    label: "Convertible",
    icon: "/icons/cabriolet.svg",
    slug: CATEGORY_KEYS.CONVERTIBLE,
  },
  [CATEGORY_KEYS.SPORT]: {
    id: CATEGORY_KEYS.SPORT,
    label: "Deportivo",
    icon: "/icons/sport.svg",
    slug: CATEGORY_KEYS.SPORT,
  },
  [CATEGORY_KEYS.MICRO]: {
    id: CATEGORY_KEYS.MICRO,
    label: "Micro",
    icon: "/icons/micro.svg",
    slug: CATEGORY_KEYS.MICRO,
  },
  [CATEGORY_KEYS.LCV]: {
    id: CATEGORY_KEYS.LCV,
    label: "Comercial Liviano",
    icon: "/icons/van.svg",
    slug: "comercial-liviano",
  },
  [CATEGORY_KEYS.MCV]: {
    id: CATEGORY_KEYS.MCV,
    label: "Comercial Mediano",
    icon: "/icons/cargo_truck.svg",
    slug: "comercial-mediano",
  },
  [CATEGORY_KEYS.HCV]: {
    id: CATEGORY_KEYS.HCV,
    label: "Comercial Pesado",
    icon: "/icons/truck.svg",
    slug: "comercial-pesado",
  },
};

export const SUBCATEGORIES = {
  [FUEL_KEYS.ELECTRIC]: {
    id: FUEL_KEYS.ELECTRIC,
    filterId: "fuel",
    label: "Eléctrico",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  [DRIVETRAIN_KEYS["4WD"]]: {
    id: DRIVETRAIN_KEYS["4WD"],
    filterId: "drivetrain",
    label: "4x4",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  [TRANSMISSION_KEYS.AUTOMATIC]: {
    id: TRANSMISSION_KEYS.AUTOMATIC,
    filterId: "transmission",
    label: "Automático/Dual",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  [TRANSMISSION_KEYS.MANUAL]: {
    id: [TRANSMISSION_KEYS.MANUAL],
    filterId: "transmission",
    label: "Manual",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
};

export const PROVINCES: Provinces = {
  alajuela: {
    id: "alajuela",
    label: "Alajuela",
    municipalities: [
      "San Carlos",
      "Zarcero",
      "Sarchí",
      "Upala",
      "Los Chiles",
      "Guatuso",
      "Río Cuarto",
      "Central",
      "San Ramón",
      "Grecia",
      "San Mateo",
      "Atenas",
      "Naranjo",
      "Palmares",
      "Poás",
      "Orotina",
    ],
  },
  cartago: {
    id: "cartago",
    label: "Cartago",
    municipalities: [
      "Central",
      "Paraíso",
      "La Unión",
      "Jiménez",
      "Turrialba",
      "Alvarado",
      "Oreamuno",
      "El Guarco",
    ],
  },
  guanacaste: {
    id: "guanacaste",
    label: "Guanacaste",
    municipalities: [
      "La Cruz",
      "Hojancha",
      "Liberia",
      "Nicoya",
      "Santa Cruz",
      "Bagaces",
      "Carrillo",
      "Cañas",
      "Abangares",
      "Tilarán",
      "Nandayure",
    ],
  },
  heredia: {
    id: "heredia",
    label: "Heredia",
    municipalities: [
      "Sarapiquí",
      "Central",
      "Barva",
      "Santo Domingo",
      "Santa Barbara",
      "San Rafael",
      "San Isidro",
      "Belén",
      "Flores",
      "San Pablo",
    ],
  },
  ["limón"]: {
    id: "limón",
    label: "Limón",
    municipalities: [
      "Central",
      "Pococí",
      "Siquirres",
      "Talamanca",
      "Matina",
      "Guácimo",
    ],
  },
  puntarenas: {
    id: "puntarenas",
    label: "Puntarenas",
    municipalities: [
      "Corredores",
      "Garabito",
      "Central",
      "Esparza",
      "Buenos Aires",
      "Montes De Oro",
      "Monte Verde",
      "Osa",
      "Quepos",
      "Golfito",
      "Coto Brus",
      "Parrita",
      "Puerto Jiménez",
    ],
  },
  ["san josé"]: {
    id: "san josé",
    label: "San José",
    municipalities: [
      "Alajuelita",
      "Vázquez De Coronado",
      "Acosta",
      "Tibás",
      "Moravia",
      "Montes De Oca",
      "Turrubares",
      "Dota",
      "Curridabat",
      "Pérez Zeledón",
      "León Cortés Castro",
      "Central",
      "Escazú",
      "Desamparados",
      "Puriscal",
      "Tarrazú",
      "Aserrí",
      "Mora",
      "Goicoechea",
      "Santa Ana",
    ],
  },
};

export const CONNECTORS = {
  acType1: {
    name: "AC tipo 1",
  },
  acType2: {
    name: "AC tipo 2",
  },
  CHAdeMO: {
    name: "CHAdeMO",
  },
  CCS: {
    name: "CCS",
  },
};

export const YES_NO_KEYS = {
  NO: "no",
  YES: "yes",
};

export const YES_NO = {
  [YES_NO_KEYS.NO]: {
    id: YES_NO_KEYS.NO,
    label: "No",
    isDefault: true,
  },
  [YES_NO_KEYS.YES]: {
    id: YES_NO_KEYS.YES,
    label: "Sí",
  },
};

export const FILTER_CATEGORIES = {
  username: {
    label: "Usuario",
  },
  search: {
    label: "Búsqueda",
  },
  brand: {
    label: "Marca",
  },
  model: {
    label: "Modelo",
  },
  category: {
    label: "Categoría",
    filter: CATEGORIES,
  },
  transmission: {
    label: "Transmisión",
    filter: TRANSMISSIONS,
  },
  fuel: {
    label: "Combustible",
    filter: FUELS,
  },
  condition: {
    label: "Condición",
    filter: CONDITIONS,
  },
  priceMin: {
    label: "Precio Min",
  },
  priceMax: {
    label: "Precio Max",
  },
  yearMin: {
    label: "Año Min",
  },
  yearMax: {
    label: "Año Max",
  },
  drivetrain: {
    label: "Tracción",
    filter: DRIVETRAINS,
  },
  location: {
    label: "Ubicación",
  },
};

// BUMP purchase logic
// bump button in vehicle detail page
// if vehicle's tier is plus or max, the button says it's free and calls the API mutation directly
// Only when the bumps for this vehicle haven't reached the max amount of bumps allowed by the tier
// If vehicle tier is free, we go to checkout page and charge the bump price
// READY in checkout page, a purchase=bump always need vehicleId in the querystring, otherwise return to homepage
// PENDING if the vehicleId belongs to a plus or max vehicle, redirect to vehicle detail page so user hits the button (item 3 above)

export type BumpType = {
  name: string;
  id: string;
  price: number;
  description: string;
  orderLabel: string;
  qty?: number;
  isRenew?: boolean;
  renewType?: string;
  bundleDiscounts?: Record<string, number>;
  getOrderLabel?: (quantity: number) => string;
};
export const BUMP: BumpType = {
  name: "Empujón",
  id: "bump",
  price: 2500,
  description: "",
  orderLabel: "1 empujón",
};

// RENEWAL purchase logic
// renew button in vehicle detail page
// if vehicle tier is free, button calls the API direcly
// if vehicle tier is plus or max, we go to checkout page with purchase=renewal-plus or purchase=renewal-max and the vehicleId
// READY in checkout page, a purchase=renewal-xxx always need vehicleId in the querystring, otherwise return to homepage
// PENDING if the vehicleId belongs to a free vehicle, redirect to vehicle detail page so user hits the button (item 3 above)

const RENEW_PLUS_DISCOUNTED_PRICE = 4800;
const RENEW_MAX_DISCOUNTED_PRICE = 5600;

export const TIER_KEYS = {
  FREE: "free",
  PLUS: "plus",
  // PREMIUM: "premium",
  MAX: "max",
  COLLECTION: "collection",
};

export const EXPIRATION_DAYS = {
  [TIER_KEYS.FREE]: 30, // This one really doesn't have an effect as listings don't expire anymore
  [TIER_KEYS.PLUS]: 30,
  [TIER_KEYS.MAX]: 60,
};

export const TIERS = {
  [TIER_KEYS.COLLECTION]: {
    name: "Collection",
    id: TIER_KEYS.COLLECTION,
    description: "",
    seo: {
      title: "Autos de Collection by Movi",
      description:
        "Nuestro servicio exclusivo de asesoría en ventas de autos premium y de lujo. Nuestra promesa de servicio es privacidad, seguridad y confianza. Nos encargamos de toda la gestión de venta de su auto.",
    },
  },
  [TIER_KEYS.FREE]: {
    name: "GRATIS",
    id: TIER_KEYS.FREE,
    price: 0,
    description:
      "Los visitantes del sitio web encontrarán tu vehículo de forma sencilla, pero habrán otras publicaciones con mayor visibilidad.",
    includedFeatures: [
      {
        id: "free-feature-1",
        positive: true,
        content: "Sin tiempo de expiración.",
      },
      {
        id: "free-feature-2",
        positive: true,
        content: "Publicación inmediata.",
      },
      // Listing does not expire anymore so renewals have been disabled
      // {
      //   id: "free-feature-4",
      //   positive: true,
      //   content: "Renovación gratuita las veces que querás.",
      // },
      {
        id: "free-feature-5",
        positive: false,
        content: "Menor visibilidad en el sitio.",
      },
      {
        id: "free-feature-3",
        positive: false,
        content: "Solo se mostrarán 5 fotografías.",
      },
      {
        id: "free-feature-6",
        positive: false,
        content: "No aparecerá en nuestros catálogos de Facebook e Instagram.",
      },
      // {
      //   id: "free-feature-5",
      //   positive: true,
      //   content: "Un empujón por ₡2500.",
      // },
      // {
      //   id: "free-feature-6",
      //   positive: false,
      //   content: "Menor duración y visibilidad que publicaciones PLUS y MAX.",
      // },
    ],
    slug: "sin-destactar",
    seo: {
      title: "Autos publicados de forma gratuita",
      description:
        "Listado de autos usados publicados de forma gratuita. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.",
    },
  },
  [TIER_KEYS.PLUS]: {
    name: "PLUS",
    id: TIER_KEYS.PLUS,
    price: 6500,
    description:
      "Publicación resaltada. Tendrá mejor visibilidad en el sitio web. Tu publicación aparecerá después de las publicaciones MAX.",
    getOrderLabel: (quantity) =>
      `${quantity} ${quantity !== 1 ? "publicaciones" : "publicación"} PLUS`,
    bundleDiscounts: {
      "5": 0.15,
      "10": 0.2,
      "15": 0.25,
      "20": 0.3,
      "25": 0.35,
    },
    includedFeatures: [
      {
        id: "plus-feature-fb",
        positive: true,
        content: (
          <span className="">
            Visible en nuestro catálogo de{" "}
            <FBLink href={`${FACEBOOK_PAGE_URL}/shop_tab`} />
          </span>
        ),
      },
      {
        id: "plus-feature-ig",
        positive: true,
        content: (
          <span className="">
            Visible en nuestro catálogo de <IGLink />
          </span>
        ),
      },
      {
        id: "plus-feature-3",
        positive: true,
        content: "Destacada en el sitio web.",
      },
      {
        id: "plus-feature-1",
        positive: true,
        content: <span>Sin tiempo de expiración.</span>,
      },
      {
        id: "plus-feature-12",
        positive: true,
        content: (
          <span>
            Destacada por{" "}
            <span className="font-bold">{EXPIRATION_DAYS.plus}</span> días.
          </span>
        ),
      },
      {
        id: "plus-feature-2",
        positive: true,
        content: "Publicación inmediata.",
      },

      {
        id: "plus-feature-5",
        positive: true,
        content: "Fotografías ilimitadas.",
      },
      {
        id: "plus-feature-4",
        positive: true,
        content: "Aparecerá temporalmente en el homepage.",
      },
      {
        id: "plus-feature-6",
        positive: true,
        content: (
          <div>
            Un empujón <span className="font-bold">GRATIS</span>
            {/* <BumpTooltip /> */}
          </div>
        ),
      },
      // Listing does not expire anymore so renewals have been disabled
      // {
      //   id: "plus-feature-7",
      //   positive: true,
      //   content: `Una renovación por ₡${formatNumber(
      //     RENEW_PLUS_DISCOUNTED_PRICE
      //   )}.`,
      // },
      // {
      //   id: "plus-feature-8",
      //   positive: false,
      //   content: "Menor duración y visibilidad que publicaciones MAX",
      // },
    ],
    seo: {
      title: "Autos destacados PLUS",
      description:
        "Listado de autos usados destacados PLUS. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.",
    },
  },
  // [TIER_KEYS.PREMIUM]: {
  //   name: "Premium",
  //   id: TIER_KEYS.PREMIUM,
  //   href: "#",
  //   price: 15,
  //   description:
  //     "Todo lo de Plus, mas visibilidad en nuestras páginas de redes sociales y en nuestra lista de correos",
  //   includedFeatures: [
  //     "Publicación de 30 días.",
  //     "Hasta 12 fotografías.",
  //     "Publicación resaltada en la página principal y en la página de búsqueda.",
  //     "Se compartirá la publicación en Facebook, Instagram y Twitter.",
  //     "Se enviará la publicación a los subscriptores de la lista de correos.",
  //   ],
  //   color: "bg-yellow-500",
  // },
  [TIER_KEYS.MAX]: {
    name: "MAX",
    id: TIER_KEYS.MAX,
    price: 7500,
    description:
      "Publicación resaltada. Máxima visibilidad en el sitio web. Tu publicación se encontrará entre las primeras que vean los visitantes por encima de las publicaciones GRATIS y PLUS.",
    getOrderLabel: (quantity) =>
      `${quantity} ${quantity !== 1 ? "publicaciones" : "publicación"} MAX`,
    bundleDiscounts: {
      "5": 0.15,
      "10": 0.2,
      "15": 0.25,
      "20": 0.3,
      "25": 0.35,
    },
    includedFeatures: [
      {
        id: "max-feature-fb",
        positive: true,
        content: (
          <span className="">
            Visible en nuestro catálogo de{" "}
            <FBLink href={`${FACEBOOK_PAGE_URL}/shop_tab`} />
          </span>
        ),
      },
      {
        id: "max-feature-ig",
        positive: true,
        content: (
          <span className="">
            Visible en nuestro catálogo de{" "}
            {/* <span className="inline self-baseline"> */}
            <IGLink />
            {/* </span> */}
          </span>
        ),
      },
      {
        id: "max-feature-pauta",
        positive: true,
        content: (
          <span className="">
            Pautado en <FBLink href={`${FACEBOOK_PAGE_URL}/shop_tab`} /> +{" "}
            <IGLink />
          </span>
        ),
      },
      {
        id: "max-feature-3",
        positive: true,
        content: "Destacada en el sitio web.",
      },
      {
        id: "max-feature-1",
        positive: true,
        content: "Sin tiempo de expiración.",
      },
      {
        id: "max-feature-12",
        positive: true,
        content: (
          <span>
            Destacada por{" "}
            <span className="font-bold">{EXPIRATION_DAYS.max}</span> días.
          </span>
        ),
      },
      {
        id: "max-feature-2",
        positive: true,
        content: "Publicación inmediata.",
      },

      {
        id: "max-feature-5",
        positive: true,
        content: "Fotografías ilimitadas.",
      },
      {
        id: "max-feature-4",
        positive: true,
        content: "Aparecerá temporalmente en el homepage.",
      },
      {
        id: "max-feature-6",
        positive: true,
        content: (
          <div>
            <span className="font-bold">Dos</span> empujones{" "}
            <span className="font-bold">GRATIS</span>
            {/* <BumpTooltip /> */}
          </div>
        ),
      },
      // Listing does not expire anymore so renewals have been disabled

      // {
      //   id: "max-feature-7",
      //   positive: true,
      //   content: `Una renovación por ₡${formatNumber(
      //     RENEW_MAX_DISCOUNTED_PRICE
      //   )}.`,
      // },
      // {
      //   id: "max-feature-0",
      //   positive: true,
      //   hideForBundle: true,
      //   content: (
      //     <span className="font-bold">
      //       Participarás en el sorteo anual de $500 para usar en el marchamo.
      //     </span>
      //   ),
      // },

      // {
      //   id: "max-feature-4",
      //   positive: true,
      //   content: "Se publicará en Facebook Marketplace.",
      // },
    ],
    seo: {
      title: "Autos destacados MAX",
      description:
        "Listado de autos usados destacados MAX. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.",
    },
  },
};

export const RENEW_PLUS_DISCOUNTED = {
  name: "PLUS",
  id: `${TIER_KEYS.PLUS}_renew_discounted`,
  tier: TIER_KEYS.PLUS,
  isRenew: true,
  renewType: "discounted",
  price: RENEW_PLUS_DISCOUNTED_PRICE,
  description: "",
  orderLabel: "1 renovación PLUS con descuento",
};

export const RENEW_MAX_DISCOUNTED = {
  name: "MAX",
  id: `${TIER_KEYS.MAX}_renew_discounted`,
  tier: TIER_KEYS.MAX,
  isRenew: true,
  renewType: "discounted",
  price: RENEW_MAX_DISCOUNTED_PRICE,
  description: "",
  orderLabel: "1 renovación MAX con descuento",
};

export const RENEW_PLUS_STANDARD = {
  name: "PLUS",
  id: `${TIER_KEYS.PLUS}_renew_standard`,
  tier: TIER_KEYS.PLUS,
  isRenew: true,
  renewType: "standard",
  price: TIERS[TIER_KEYS.PLUS].price,
  description: "",
  orderLabel: "1 renovación PLUS",
};

export const RENEW_MAX_STANDARD = {
  name: "MAX",
  id: `${TIER_KEYS.MAX}_renew_standard`,
  tier: TIER_KEYS.MAX,
  isRenew: true,
  renewType: "standard",
  price: TIERS[TIER_KEYS.MAX].price,
  description: "",
  orderLabel: "1 renovación MAX",
};

export const VALID_PURCHASES = {
  [TIER_KEYS.COLLECTION]: TIERS[TIER_KEYS.COLLECTION],
  [TIER_KEYS.PLUS]: TIERS[TIER_KEYS.PLUS],
  //
  [`${TIER_KEYS.PLUS}_5`]: { ...TIERS[TIER_KEYS.PLUS], qty: 5 },
  [`${TIER_KEYS.PLUS}_10`]: { ...TIERS[TIER_KEYS.PLUS], qty: 10 },
  [`${TIER_KEYS.PLUS}_15`]: { ...TIERS[TIER_KEYS.PLUS], qty: 15 },
  [`${TIER_KEYS.PLUS}_20`]: { ...TIERS[TIER_KEYS.PLUS], qty: 20 },
  [`${TIER_KEYS.PLUS}_25`]: { ...TIERS[TIER_KEYS.PLUS], qty: 25 },

  [TIER_KEYS.MAX]: TIERS[TIER_KEYS.MAX],
  //
  [`${TIER_KEYS.MAX}_5`]: { ...TIERS[TIER_KEYS.MAX], qty: 5 },
  [`${TIER_KEYS.MAX}_10`]: { ...TIERS[TIER_KEYS.MAX], qty: 10 },
  [`${TIER_KEYS.MAX}_15`]: { ...TIERS[TIER_KEYS.MAX], qty: 15 },
  [`${TIER_KEYS.MAX}_20`]: { ...TIERS[TIER_KEYS.MAX], qty: 20 },
  [`${TIER_KEYS.MAX}_25`]: { ...TIERS[TIER_KEYS.MAX], qty: 25 },

  // [RENEW_PLUS_DISCOUNTED.id]: RENEW_PLUS_DISCOUNTED,
  // [RENEW_MAX_DISCOUNTED.id]: RENEW_MAX_DISCOUNTED,
  // [RENEW_PLUS_STANDARD.id]: RENEW_PLUS_STANDARD,
  // [RENEW_MAX_STANDARD.id]: RENEW_MAX_STANDARD,
  [BUMP.id]: BUMP,
};
export type ValidPurchaseDataType = typeof VALID_PURCHASES.keys;

export const VEHICLE_REQUIRED_IN_PURCHASES = [
  // TIER_KEYS.PLUS,
  // TIER_KEYS.MAX,
  RENEW_PLUS_DISCOUNTED.id,
  RENEW_MAX_DISCOUNTED.id,
  RENEW_PLUS_STANDARD.id,
  RENEW_MAX_STANDARD.id,
  BUMP.id,
];

export const EQUIPMENT = {
  HYDRAULIC_STEERING: {
    labelEn: "Hydraulic steering",
    labelEs: "Dirección hidráulica",
  },
  ELECTRIC_STEERING: {
    labelEn: "Electronic steering",
    labelEs: "Dirección electrónica",
  },
  CENTRAL_LOCKING: {
    labelEn: "Central locking",
    labelEs: "Cierre central",
  },
  ELECTRIC_CAR_SEATS: {
    labelEn: "Electric seats",
    labelEs: "Asientos eléctricos",
  },
  ELECTRIC_WINDOWS: {
    labelEn: "Electric windows",
    labelEs: "Vidrios eléctricos",
  },
  TINTED_WINDOWS: {
    labelEn: "Tinted windows",
    labelEs: "Vidrios polarizados",
  },
  ELECTRIC_MIRRORS: {
    labelEn: "Electric mirrors",
    labelEs: "Espejos eléctricos",
  },
  AUTO_RETRACTABLE_MIRRORS: {
    labelEn: "Auto-retractable mirrors",
    labelEs: "Retrovisores auto-retractables",
  },
  AIRBAGS: {
    labelEn: "Airbags",
    labelEs: "Bolsas de aire",
  },
  AIR_CONDITIONED: {
    labelEn: "Air conditioned",
    labelEs: "Aire acondicionado",
  },
  ALARM: {
    labelEn: "Alarm",
    labelEs: "Alarma",
  },
  DEFOGGERS: {
    labelEn: "Window defoggers",
    labelEs: "Desempañadores de ventana",
  },
  PREMIUM_WHEELS: {
    labelEn: "Premium wheels",
    labelEs: "Aros de lujo",
  },
  TURBO: {
    labelEn: "Turbo",
    labelEs: "Turbo",
  },
  LED_LIGHTS: {
    labelEn: "LED lights",
    labelEs: "Luces LED",
  },
  HALOGEN_LIGHTS: {
    labelEn: "Halogen lights",
    labelEs: "Halógenos",
  },
  BLUETOOTH: {
    labelEn: "Bluetooth",
    labelEs: "Bluetooth",
  },
  USB: {
    labelEn: "USB",
    labelEs: "USB",
  },
  AUDIO_AUX: {
    labelEn: "Audio aux. input",
    labelEs: "Entrada auxiliar de audio",
  },
  CD_PLAYER: {
    labelEn: "CD player",
    labelEs: "Reproductor de CDs",
  },
  CRUISE_CONTROL: {
    labelEn: "Cruise control",
    labelEs: "Control crusero",
  },
  DRIVER_ASSISTANCE: {
    labelEn: "Driver-assistance System",
    labelEs: "Manejo asistido",
  },
  DESCENT_CONTROL: {
    labelEn: "Descent control",
    labelEs: "Control de descenso",
  },
  REAR_CAMERA: {
    labelEn: "Rear-view camera",
    labelEs: "Cámara trasera",
  },
  OVERHEAD_CAMERA: {
    labelEn: "Overhead/360° camera",
    labelEs: "Cámara 360°",
  },
  PROXIMITY_SENSOR: {
    labelEn: "Proximity sensor",
    labelEs: "Sensor de proximidad",
  },
  ABS: {
    labelEn: "ABS breaking system",
    labelEs: "Frenos ABS",
  },
  STEERING_WHEEL_CONTROLS: {
    labelEn: "Steering wheel controls",
    labelEs: "Controles en el volante",
  },
  ADJUSTABLE_STEERING_WHEEL: {
    labelEn: "Adjustable steering wheel",
    labelEs: "Volante ajustable",
  },
  TOUCH_SCREEN: {
    labelEn: "Touchscreen",
    labelEs: "Pantalla táctil",
  },
  RAIN_SENSOR: {
    labelEn: "Rain sensors",
    labelEs: "Sensores de lluvia",
  },
  TIRE_PRESSURE_MONITORING_SYSTEM: {
    labelEn: "Tire pressure monitoring system",
    labelEs: "Monitor de presión de llantas",
  },
  SURROUND_SOUND_SYSTEM: {
    labelEn: "Surround sound system",
    labelEs: "Sistema de sonido envolvente",
  },
  SPORT_EXHAUST_SYSTEM: {
    labelEn: "Sport exhaust system",
    labelEs: "Sistema de escape deportivo",
  },
  REGENERATIVE_BREAKING: {
    labelEn: "Regenerative breaking",
    labelEs: "Frenado regenerativo",
  },
  CHADEMO_PLUG: {
    labelEn: "CHAdeMO plug",
    labelEs: "Conector CHAdeMO",
  },
  AC_TYPE_1_PLUG: {
    labelEn: "AC type 1 plug",
    labelEs: "Connector AC tipo 1",
  },
  AC_TYPE_2_PLUG: {
    labelEn: "AC type 2 plug",
    labelEs: "Connector AC tipo 2",
  },
  CCS_PLUG: {
    labelEn: "CCS plug",
    labelEs: "Connector CCS",
  },
};

export const bannersData = [
  {
    id: "buffer-banner",
    trackId: "buffer-banner",
    image: "/partners/buffer3.png",
    link: "https://www.instagram.com/buffercr/",
    alwaysShown: true,
  },
  // {
  //   id: "lubrionline-banner",
  //   trackId: "lubrionline-banner",
  //   image: "/partners/lubri_online.jpg",
  //   link: "https://www.instagram.com/lubrionlinecr/",
  //   alwaysShown: true,
  // },
  {
    id: "financiero-banner",
    image: "/partners/financiero.png",
    trackId: "finnaciero-banner",
    link: "https://www.elfinancierocr.com/emprender/como-obtener-un-espacio-en-un-mercado-con-varios/4KHK6CZELBDDVIDG6QILTVXIQI/story/",
    linkLabel: "Leer artículo",
    alwaysShown: true,
  },
  // {
  //   id: "movi-2-banner",
  //   image: "/partners/superwheels.jpg",
  //   link: "https://www.instagram.com/superwheelscr/",
  // },
  // {
  //   id: "lubrionline-banner-2",
  //   trackId: "lubrionline-banner",
  //   image: "/partners/lubri_online.jpg",
  //   link: "https://www.instagram.com/lubrionlinecr/",
  // },
  {
    id: "buffer-banner-2",
    trackId: "buffer-banner",
    image: "/partners/buffer3.png",
    link: "https://www.instagram.com/buffercr/",
  },
  // {
  //   id: "movi-3-banner",
  //   image: "/partners/financiero.png",
  //   link: "https://www.elfinancierocr.com/emprender/como-obtener-un-espacio-en-un-mercado-con-varios/4KHK6CZELBDDVIDG6QILTVXIQI/story/",
  //   linkLabel: "Leer artículo",
  // },
  // {
  //   id: "movi-4-banner",
  //   image: "/partners/financiero.png",
  //   link: "https://www.elfinancierocr.com/emprender/como-obtener-un-espacio-en-un-mercado-con-varios/4KHK6CZELBDDVIDG6QILTVXIQI/story/",
  //   linkLabel: "Leer artículo",
  // },
];

// Build these dynamically from the brands.json
export const BRAND_SLUGS = brands
  .filter((brand) => brand.slug)
  .map((brand) => ({
    id: brand.slug,
    type: "brand",
    filterValue: [brand.slug],
    seo: {
      title: `Autos usados ${capitalizeAllWords(brand.name.toLowerCase())}`,
      description: `Listado de autos usados ${capitalizeAllWords(
        brand.name.toLowerCase()
      )}. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.`,
    },
  }));

export const PROVINCE_SLUGS = Object.keys(PROVINCES).map((provinceKey) => ({
  id: generateSlug(provinceKey),
  type: "province",
  filterValue: [provinceKey],
  seo: {
    title: `Autos usados en ${PROVINCES[provinceKey].label}`,
    description: `Listado de autos usados en ${PROVINCES[provinceKey].label}. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.`,
  },
}));

// [TIER_KEYS.COLLECTION]: {
//   name: "Collection",
//   id: TIER_KEYS.COLLECTION,
//   description: "",
//   seo: {
//     title: 'Autos de Collection by Movi',
//     description: 'Servicio exclusivo de asesoría en ventas de autos premium y de lujo.'
//   }
// },

export const TIER_SLUGS = Object.keys(TIERS).map((tierKey) => ({
  id: TIERS[tierKey].slug || tierKey,
  type: "listingTier",
  filterValue: tierKey,
  seo: TIERS[tierKey].seo,
}));

export const CATEGORY_SLUGS = Object.keys(CATEGORIES).map((categoryKey) => ({
  id: CATEGORIES[categoryKey].slug,
  type: "category",
  filterValue: [categoryKey],
  seo: {
    title: `Autos estilo ${CATEGORIES[categoryKey].label} en Costa Rica`,
    description: `Listado de autos estilo ${CATEGORIES[categoryKey].label}. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.`,
  },
}));

export const ELECTRIC_SLUG = {
  id: "electricos",
  type: "fuel",
  filterValue: FUEL_KEYS.ELECTRIC,
  seo: {
    title: `Autos eléctricos en Costa Rica`,
    description: `Listado de autos eléctricos. Movi es el sitio web más fácil y seguro para comprar y vender de autos en Costa Rica.`,
  },
};

export const SLUGS_METADATA = [
  ...BRAND_SLUGS,
  ...PROVINCE_SLUGS,
  ...TIER_SLUGS,
  ...CATEGORY_SLUGS,
  ...MODEL_SLUGS,
  ELECTRIC_SLUG,
];

export const RESTRICTED_USERNAME_HANDLES = [...SLUGS_METADATA];

export const getSlugMetadata: (slug: string) => SlugMetadata = (slug: string) =>
  SLUGS_METADATA.filter((slugObj) => slugObj.id).find(
    (slugObj) => slugObj.id === slug
  ) as SlugMetadata;
